//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//
@import url("https://fonts.cdnfonts.com/css/chunkfive");
@import url("https://fonts.cdnfonts.com/css/playlist");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;1,300;1,400;1,500&family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;700&family=Open+Sans:wght@700&display=swap");

@font-face {
  font-family: "TheBoston";
  src: url("../../../../../src/app/modules/marketing/modals/digitalTemplate/css/fonts/TheBoston.ttf");
}

@font-face {
  font-family: "Garmani";
  src: url("./Garmani.ttf");
}

$colours: "1_on_1" #c2e2bf, "white" #ffff, "black" rgb(0, 0, 0), "buyer" #bddff6,
  "general" #d1d3d4, "listing" #fcf7bc, "darkenListing" #c4bc6d,
  "rental_open_home" #d0bedd, "open_home" #f37d80, "market_review" #ffdfba,
  "rental" #f8b1b9, "rental_open_home" #d0bedd, "seller_visit" #ab9ecc,
  "tenant" #d789ba, "virtual_listing" #88cfbd, "virtual_buyer" #8abde6,
  "brand" #822433, "red" #f44336, "blue" #463bdd, "darkRed" #4c0e1b,
  "darkGrey" #414042, "darkBlue" #c0d9e6, "lightBlue" #eff9fe, "green" #2bb673,
  "lightGrey" #a7a9ac, "landlord" #f8b1b9, "prospect" #fcf7bc, "seller" #ffdfba,
  "darkenSeller" #ffdfba, "servicer" #d1d3d4, "oneToOne" #c2e2bf,
  "outgoing_inspection" #d1d3d4, "ingoing_inspection" #d1d3d4,
  "rental_routine" #d1d3d4 "auction" #9bf9a2;

$viewPort: 50vh, 60vh, 70vh, 80vh;

@each $view in $viewPort {
  .view-#{$view} {
    height: $view;
  }
}

@each $i in $colours {
  .#{nth($i, 1)}-background {
    background-color: nth($i, 2) !important;
  }

  .#{nth($i, 1)}-border-left-card {
    width: "100%";
    border-left: 7px solid nth($i, 2);
    box-shadow: 0 0.4rem 1rem #c7c7c7 !important;
  }
  .#{nth($i, 1)}-bullet-calender-date {
    border-radius: 50%;
    width: 15px;
    border: 1px solid #acacac;
    height: 15px;
    color: nth($i, 2);
  }

  .#{nth($i, 1)}-color {
    color: nth($i, 2) !important;
  }

  .#{nth($i, 1)}-border-left {
    border-left: 4px solid nth($i, 2);
    height: -webkit-fill-available;
  }

  .#{nth($i, 1)}-border {
    border: 1px solid nth($i, 2);
  }

  .#{nth($i, 1)}-border-2 {
    border: 2px solid nth($i, 2);
  }

  .#{nth($i, 1)}-button {
    max-height: 34px;
    background-color: nth($i, 2) !important;
    font-weight: bold !important;

    &:hover {
      background-color: darken(nth($i, 2), 10%) !important;
      color: white !important;
    }

    &.active {
      background-color: darken(nth($i, 2), 10%) !important;
      color: white !important;
    }
  }

  .#{nth($i, 1)}-icon-button {
    color: nth($i, 2) !important;

    &:hover {
      color: darken(nth($i, 2), 10%) !important;
    }

    &.active {
      color: darken(nth($i, 2), 10%) !important;
    }

    &:disabled {
      color: lighten(nth($i, 2), 50%) !important;
    }
  }

  .#{nth($i, 1)}-svg-button {
    fill: nth($i, 2) !important;

    &:hover {
      fill: darken(nth($i, 2), 10%) !important;
    }

    &.active {
      fill: darken(nth($i, 2), 10%) !important;
    }
  }

  .note-item-#{nth($i, 1)} {
    border-left: 6px solid nth($i, 2) !important;
  }

  .themeButton-toggle-group {
    &.#{nth($i, 1)} {
      width: 100%;

      label {
        &.active {
          background-color: nth($i, 2) !important;
          color: black !important;
          font-weight: bold;
        }

        &:hover {
          background-color: nth($i, 2) !important;
          color: black !important;
          font-weight: bold;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem nth($i, 2) !important;
        }
      }
    }
  }
}

.timeline-button {
  color: #414042 !important;
  text-transform: uppercase;
  // border: 3px solid #BDDFF6;
  background-color: #c0d9e6;
  font-weight: bold;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.my-facebook-button-class {
  border: none;
  background: #fff;
}

.small-icon {
  font-size: 14px !important;
}

.small-font {
  font-size: 14px !important;
}

.submit-button {
  background-color: #2bb673 !important;
  color: #fff !important;
  font-weight: bold;

  &:hover {
    background-color: #2bb673 !important;
    color: #fff !important;
  }

  &:disabled {
    background-color: #fff !important;
    color: #d1d3d4 !important;
    border: 2px solid #d1d3d4;
    font-weight: bold;
  }
}

.submit-icon-button {
  color: #2bb673 !important;

  &:hover {
    color: #2bb673 !important;
  }

  &:disabled {
    color: #d1d3d4 !important;
  }
}

.tox .tox-statusbar__branding svg {
  fill: #ffffff !important;
}
.tox-statusbar__path-item {
  color: #ffffff !important;
}

.width-box-text {
  width: 92% !important;
}
.divider {
  margin: 2rem !important;
}

.dot {
  margin-right: 1rem;
  height: 1rem;
  width: 1rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;

  &.green {
    background-color: #2bb673;
  }

  &.red {
    background-color: #f37d80;
  }

  @each $i in $colours {
    &.#{nth($i, 1)}s {
      background-color: nth($i, 2);
    }
  }

  @each $i in $colours {
    &.#{nth($i, 1)} {
      background-color: nth($i, 2);
    }
  }
}

.red-text {
  .MuiInputBase-input {
    color: red;
  }
}

.green-text {
  .MuiInputBase-input {
    color: green;
  }
}

.MuiStepButton-root {
  .MuiStepIcon-root {
    color: white !important;
    border: 1px solid #c0d9e6 !important;
    border-radius: 50%;

    .MuiStepIcon-text {
      fill: #c0d9e6 !important;
    }
  }

  .MuiStepIcon-active {
    color: #c0d9e6 !important;

    .MuiStepIcon-text {
      fill: black !important;
    }
  }
}

.price-history {
  padding-top: 19px;
  background: beige;
  margin-left: -38.8px;
  margin-right: -38.8px;
  margin-top: 3px;
  background-color: kt-state-color(wb-dark-blue) !important;
}

table {
  @each $i in $colours {
    &.#{nth($i, 1)} {
      border: 2px solid nth($i, 2) !important;

      thead {
        background-color: nth($i, 2) !important;
      }
    }
  }
}

.dashboard-stat {
  > .row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between;

    .MuiChip-root {
      flex: 0 0 auto;
    }
  }
}

.dashboard-list {
  .active {
    background-color: #822433;
  }
}

input[type="radio"]:checked:after {
  background-color: kt-state-color(brand) !important;
}

.default-icon-button {
  color: kt-state-color(wb-light-grey) !important;

  &:hover {
    color: kt-state-color(wb-light-grey) !important;
  }

  &.active {
    border: 0px;
    color: kt-state-color(brand) !important;
  }

  &.focus {
    border: 0px;
    box-shadow: unset;
  }
}

.note-thumbtack {
  fill: kt-state-color(wb-dark-blue);
}

.listing-new-icon {
  height: 20px;
  width: 40px;
}

.pinned {
  fill: kt-state-color(brand) !important;
}

.feature_table {
  overflow-y: auto;
  border: 3px solid #822633;
}

// Diary Appointment

@each $i in $colours {
  .#{nth($i, 1)}-appointment {
    .tableFixHead {
      border: 3px solid nth($i, 2) !important;
    }

    .tableTheme {
      border: 3px solid nth($i, 2) !important;
    }

    thead {
      background-color: nth($i, 2) !important;
    }

    table td + td {
      border-left: 2px solid nth($i, 2) !important;
    }

    thead {
      border: 2px solid nth($i, 2) !important;

      th {
        background: nth($i, 2) !important;
        z-index: 1;
      }
    }

    //

    .themeButton-toggle-group {
      label {
        border-color: nth($i, 2) !important;

        &.active {
          background-color: nth($i, 2) !important;
          color: black !important;
          font-weight: bold;
        }

        &:hover {
          background-color: nth($i, 2) !important;
          color: black !important;
          font-weight: bold;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem nth($i, 2) !important;
        }
      }
    }
  }
}

.tableTheme {
  overflow-y: auto;

  // height: 75vh;

  @each $i in $colours {
    &.#{nth($i, 1)} {
      border: 3px solid nth($i, 2);

      thead {
        background-color: nth($i, 2) !important;
      }

      table td + td {
        border-left: 2px solid nth($i, 2) !important;
      }

      thead {
        border: 2px solid nth($i, 2) !important;

        th {
          background: nth($i, 2) !important;
          z-index: 1;
        }
      }
    }
  }

  &.selected {
    td {
      color: kt-state-color(wb-light-grey);
    }
  }

  table td + td {
    border-right: 2px solid #82263382;
  }

  thead {
    border: 2px solid #822633;

    th {
      background: #822633;
      text-align: center;
      position: sticky;
      top: 0;
      color: #fff;
      z-index: 1;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }

  tbody {
    tr {
      td:nth-child(1) {
        padding-left: 1.5rem;
      }
    }
  }
}

.table-responsive {
  overflow: scroll;

  thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.border-right {
  border-right: 1px solid kt-state-color(wb-light-grey);
}

// $button-colours: "brand"#822433,
// "darkRed"#4C0E1B,
// "darkGrey"#414042,
// "darkBlue"#C0D9E6,
// "lightBlue"#EFF9FE,
// "green"#2BB673,
// "lightGrey"#A7A9AC;

// @each $i in $button-colours {

//     .#{nth($i, 1)}-button {
//         background-color: nth($i, 2) !important;

//         &:hover {
//             background-color: darken(nth($i, 2), 10%) !important;
//         }
//         &.active {
//             background-color: darken(nth($i, 2), 10%) !important;
//             color: black !important;
//             font-weight: bold;
//         }
//     }

//     .#{nth($i, 1)}-icon-button {
//         color: nth($i, 2) !important;

//         &:hover {
//             color: darken(nth($i, 2), 10%) !important;
//         }
//     }
// }

.white-button {
  background-color: white !important;

  &:hover {
    background-color: darken(white, 10%) !important;
  }
}

.white-icon-button {
  color: white !important;

  &:hover {
    color: darken(white, 10%) !important;
  }
}

.max-w {
  width: max-content;
}

.themeButton {
  padding: 0.5rem 2rem 0.5rem 2rem !important;
  color: white;
  width: max-content;
}

.btn.themeButton {
  color: white;
}

.themeButtonSmall {
  padding: 0.1rem 0.5rem 0.1rem 0.5rem !important;
  color: white;
  width: max-content;
}

.modal-header {
  border: 0px;
}

.toggleButtons {
  padding: 0.5rem 2rem 0.5rem 2rem !important;
  background-color: white;
  color: black;
  font-weight: bold;
  border-color: kt-state-color(wb-dark-blue) !important;

  &.active {
    background-color: kt-state-color(wb-dark-blue) !important;
    color: black !important;
    font-weight: bold;
  }

  &:hover {
    background-color: kt-state-color(wb-dark-blue) !important;
    color: black !important;
    font-weight: bold;
  }
}

.toggle-button-group {
  button {
    width: 50%;
    padding: 5px;
  }
}

.themeButton-toggle-group {
  label {
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: white;
    color: black;
    font-weight: bold;
    border-color: kt-state-color(wb-dark-blue) !important;

    &.active {
      background-color: kt-state-color(wb-dark-blue) !important;
      color: black !important;
      font-weight: bold;
    }

    &:hover {
      background-color: kt-state-color(wb-dark-blue) !important;
      color: black !important;
      font-weight: bold;
    }
  }
}

// Output

// .red-background {
// 	background: #FF0000;
// }

// .yellow-color {
// 	color: #F6FF00;
// }

// end Output

.my-custom-style {
  padding: 1rem 0.5rem;
  color: kt-brand-color();

  .my-custom-element {
    margin-left: 1rem;
  }
}

@include kt-tablet-and-mobile {
  .my-custom-style {
    padding: 0.5rem 0.25rem;

    .my-custom-element {
      margin-left: 0.5rem;
    }
  }
}

// hr

hr {
  border-color: kt-state-color(wb-light-grey) !important;
}

.gutter-b {
  margin-bottom: 20px;
}

.card-stretch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 99%;
}

#content {
  white-space: nowrap;
}

.theme-button {
  color: #ffff;
}

.button-hover-dark:hover {
  filter: brightness(85%);
}

.button-hover-light:hover {
  color: white !important;
  filter: brightness(85%);
}

.theme-table-striped {
  tbody {
    tr {
      height: 37px;
    }

    tr:nth-child(even) {
      background-color: kt-state-color(wb-light-blue);
    }
  }
}

.label {
  margin: 2px;
}

.label-brand {
  color: white;
  background-color: kt-state-color(brand);
  padding: 5px 10px 5px 10px;
  margin: 2px;
}

// tableFixHead

.dashboard-table {
  .tableFixHead {
    border: 3px solid kt-state-color(wb-dark-blue);
    overflow-y: auto;
    height: 76vh;

    table td + td {
      border-left: 1px solid kt-state-color(wb-dark-blue);
      padding-bottom: 0px;
    }

    .table td {
      border-top: 1px solid #ffffff;
    }

    thead {
      tr:nth-child(1) {
        display: none;
      }

      th {
        background: kt-state-color(wb-dark-blue);
        font-weight: bold;
        z-index: 30;
        text-align: center;
        position: sticky;
        top: 0;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        color: #fff;
      }
    }

    tbody {
      th {
        border-top: 0px;
      }

      tr {
        td:nth-child(1) {
          text-align: center;
        }

        i {
          padding: 5px;
        }
      }

      td {
        width: max-content;
      }

      p {
        padding-bottom: 0px;
        margin-bottom: 0px;
      }

      .center {
        text-align: center;
      }
    }

    tfoot {
      tr:last-child {
        display: none;
      }

      td {
        background: kt-state-color(wb-dark-blue);
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;

        z-index: 4;
      }
    }
  }
}

.tableFixHead {
  border: 3px solid #822633;
  overflow-y: auto;
  // height: 76vh;

  table td + td {
    border-left: 1px solid #82263382;
    padding-bottom: 0px;
  }

  .table td {
    border-top: 1px solid #ffffff;
  }

  thead {
    tr:nth-child(1) {
      display: none;
    }

    th {
      background: #822633;
      font-weight: bold;
      z-index: 30;
      text-align: center;
      position: sticky;
      top: 0;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      color: #fff;
    }
  }

  .tbody-result {
    background-color: #c0d9e6;
    border-top: 2px solid #822633 !important;

    tr:nth-child(2n) {
      background-color: #c0d9e6;
    }
  }

  .table tbody + tbody {
    border-top: 0px;
  }

  tbody {
    tr {
      td:nth-child(1) {
        text-align: center;
      }

      i {
        padding: 5px;
      }
    }

    td {
      width: max-content;
    }

    p {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    .center {
      text-align: center;
    }
  }

  tfoot {
    tr:last-child {
      display: none;
    }

    td {
      background: kt-state-color(wb-dark-blue);
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;

      z-index: 4;
    }
  }
}

.tbody-scrollable {
  display: block;
  overflow: auto;

  tr {
    display: block;

    td {
      display: block;
    }
  }
}

.row-selected {
  background-color: kt-state-color(wb-dark-blue) !important;

  td {
    font-weight: bold;
    color: black !important;
  }
}

//   Modal
.modal-backdrop {
  z-index: 5;
}

.weekly-breakdown {
  .MuiInput-input {
    text-align: center;
  }
}

// Tabs

.app-bar {
  box-shadow: unset !important;
  color: black !important;
  background-color: transparent !important;

  .Mui-selected {
    background-color: kt-state-color(wb-dark-blue);
    font-weight: bold;
    color: black;
  }
}

.Mui-selected {
  background-color: kt-state-color(wb-dark-blue) !important;
  font-weight: bold;
  color: black;
}

// Custom Input
.group-icon-input {
  .col-form-label {
    padding: 0px;
    text-align: end;
    margin: auto;
  }

  i {
    text-align: center;
    font-size: 15px;
    border: 2px solid;
    width: 30px;
    padding: 6px;
    height: 30px;
    border-radius: 50%;
  }
}

// Button

.btn-full {
  padding: 5px;
  align-items: center;
  font-weight: 600;
}

.title {
  color: kt-state-color(brand);
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  margin: 0px;
  max-width: max-content;
  /* padding-bottom: 10px; */
}

// MultiSelect

.multiSelect {
  .selectedItems {
    .btn-group {
      padding: 3px 6px 3px 0px;
    }
  }

  // >div:first-child>div:first-child>div:first-child {
  //     height: 30px;
  // }
  .multiSelect__multi-value {
    color: rgba(0, 0, 0, 0.87);
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: arial;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
  }

  .multiSelect__multi-value__remove {
    margin-right: 5px;

    &:hover {
      background-color: unset;
      color: #de350b;
      margin-right: 5px;
    }
  }

  .multiSelect__indicator-separator {
    display: none;
  }

  .multiSelect__value-container {
    padding: 0px;
  }

  .multiSelect__placeholder {
    top: 67%;
  }

  .multiSelect__dropdown-indicator {
    padding: 0px;
    padding-right: 0px;
    color: rgba(0, 0, 0, 0.54);
  }

  .multiSelect__control {
    min-height: unset;
    border: unset;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    border-radius: 0px;
    box-shadow: unset !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;

    &:hover {
      box-shadow: unset;
      border-color: unset;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      border-right: 0px;
      border-left: 0px;
      border-top: 0px;
    }

    &:focus {
      box-shadow: unset;
      border-color: unset;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      border-right: 0px;
      border-left: 0px;
      border-top: 0px;
    }
  }
}

.multiSelect__menu {
  background-color: #822433;
}

// From-Group
.form-group {
  margin-bottom: 1rem;

  label {
    font-size: 10px;
    font-weight: 400;
  }
}

// Dropzone

.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #ebedf3;
  border-radius: 0.42rem;
  color: #a7a9ac;
}

// Button

.filter-button {
  width: 100%;
  font-weight: bold;
  background-color: white;
  border: 2px solid kt-state-color(wb-light-grey);
  color: kt-state-color(wb-light-grey);

  &hover {
    background-color: kt-state-color(wb-green);
    color: white;
  }
}

// MultiSelect

// // DateTime-Picker
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  width: max-content;
  z-index: 100 !important;
}

// Nav icon

.nav-icon-button {
  i {
    padding: 3px 10px 3px 10px;
    background: kt-state-color(wb-dark-blue) !important;
    border-radius: 4px;
  }
}

// profile-basicInfo-carousel

.profile-basicInfo-carousel {
  .carousel-indicators {
    bottom: -3%;

    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background-color: kt-state-color(brand) !important;
    }
  }

  .carousel-control-next {
    bottom: unset;
    top: 6vh;

    i {
      font-size: 30px;
      color: kt-state-color(wb-dark-grey) !important;
    }
  }
}

//
.min-content {
  min-width: max-content;
}

//

.font-smaller {
  font-size: 80%;
}

.timeline-body {
  max-height: 81vh;
  overflow: auto;
}

// Drawer

.drawer-list {
  li {
  }

  li.active {
    border-left: 5px solid kt-state-color(brand);
    font-weight: 600;
    color: kt-state-color(brand);
    background: kt-state-color(wb-dark-blue);
  }
}

//   Fade

.fade-image {
  position: absolute;
  bottom: 0px;

  display: block;

  width: 100%;
  height: 50px;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  );
}

// label

.icon-label {
  padding: 5px;

  i {
    padding-left: 3px;
  }
}

// Button

.edit-btn {
  position: absolute;
  right: 0px;
}

.icon-btn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 1.5rem;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 0px;
  background-color: unset !important;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -o-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.icon-btn2 {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 1.5rem;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 0px;
  background-color: unset;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -o-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

// Image

.circle-avatar {
  width: 150px;
  height: 150px;
}

// Item

.item {
  padding-bottom: 10px;
  padding-top: 10px;
}

//
.mini-icon {
  width: 25px !important;
  height: 25px !important;
}

.square-button {
  border: 1px solid kt-state-color(wb-dark-grey);
  font-weight: bold;
  font-size: 0.75rem !important;
  background-color: transparent !important;
}

.sortable-element {
  z-index: 10000;
}

// Table dnd
body > tr {
  background-color: #f7f8fa;
  display: table !important;

  td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
}

//   Map marker

.marker-button {
  .svg-icon {
    height: 30px;
    width: 30px;
  }
}

// textAreaEmoji-wrapper

.sms-view {
  .textAreaEmoji {
    justify-content: center;
    // padding: 3rem;
    padding-top: 1rem;
    padding-bottom: 0rem;

    .textAreaEmoji-wrapper {
      width: 100%;

      display: flex;
      flex-direction: column-reverse;

      .textAreaEmoji-editor {
        height: 500px;
        border: 1px solid kt-state-color(wb-light-grey);
        // padding: 10px 0px 10px 10px;
        padding: 2rem;
      }

      .rdw-editor-toolbar {
        position: absolute;
        top: 12%;
        right: 5%;
        z-index: 999;
        margin: 0px;
        padding: 0px;
      }

      .rdw-emoji-wrapper {
        margin-bottom: 0px;
      }

      .rdw-option-wrapper {
        padding: 0px;
        min-width: unset;
        margin: 0px;
        margin-bottom: 0px;
      }
    }
  }
}

// Tab

.equal-tab {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    color: black;
  }
}

// scrapPaper

.scrapPaperTable {
  .table {
    .row-selected {
      background-color: white !important;

      td {
        font-weight: bold;
        color: kt-state-color(wb-green) !important;
      }

      .form-check .form-check-input:checked ~ .custom-control-label::before {
        background-color: kt-state-color(wb-green) !important;
      }

      .form-check
        .form-check-input:checked:focus
        ~ .custom-control-label::before {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25);
      }

      .form-check .form-check-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
      }

      .form-check .form-check-input:active ~ .custom-control-label::before {
        background-color: #c8ffc8;
      }

      input[type="checkbox"]:before {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        -ms-transition: 0.2s;
        transition: 0.2s;
        background: #fff;
      }

      input[type="checkbox"]:checked:before {
        background-color: kt-state-color(wb-green) !important;
      }
    }
  }
}

.flex-column-item {
  > div {
    margin: 0 10px 10px 10px;
  }
}

// Note Form
.note-form {
  position: relative;

  textarea {
    font-size: 18px;
  }

  .dictaphone {
    right: 0px;
    //top: 10px;
    margin-bottom: 10px;
    position: relative;
    font-size: 1.25rem;
  }

  .add-tags {
    position: absolute;
    right: 0px;
    padding: 6px;

    & i {
      font-size: 15px;
    }
  }

  .no-position {
    position: relative !important;
  }

  .box-icons-text-editor {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container-buttons-notes {
    display: flex;
  }

  .buttons-notes-mobile {
    margin: 0 !important;
    height: 46px;
    width: 33.3%;
    border-bottom: solid 0.5px #82243340;
    border-top: solid 0.5px #82243340;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    &.left {
      border-bottom-left-radius: 15px !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: solid 0.5px #82243340 !important;
    }
  }

  .add-note {
    position: relative;
    // top: 50px;
    right: 0px;
    padding: 6px;
  }

  .edit-note {
    position: absolute;
    right: 30px;
    padding: 10px;

    & i {
      font-size: 20px;
    }
  }

  .close-note {
    position: absolute;
    top: 1px;
    right: 0px;
    padding: 10px;

    & i {
      font-size: 21px;
    }
  }

  .note-form-textarea {
    border-radius: 50px;
    border-left: 6px solid kt-state-color(wb-dark-grey);
    height: 100%;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }
}

.mobile-notes-details {
  font-size: 20px;
  padding-right: 2.5rem;
  border: solid 0.5px #8224332e;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.container-note-form-mobile {
  display: flex !important;
  flex-direction: column;
}

.button-add-note-form {
  width: 33.3%;
  border: solid 0.5px #8224332e !important;
  border-bottom-right-radius: 15px !important;
  height: 46px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.hoverEffect {
  position: relative;

  .hoverEffectMain {
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    backface-visibility: hidden;
    p {
      margin: 0 !important;
    }
  }

  .hoverEffectItem {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  &:hover {
    background-color: lightblue;

    .hoverEffectMain {
      opacity: 0.3;
    }

    .hoverEffectItem {
      opacity: 1;
    }
  }
}

.rightButton {
  z-index: 2;
}

//   Step Button custom

.stepButton {
  .MuiStepLabel-iconContainer {
    transform: scale(2);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
  }
}

//
.max-content {
  width: max-content;
}

//
.app-bar {
  button {
    padding: 0px 0px;
    min-height: 35px;
    border-bottom: 2px solid #c0d9e6;
  }
}

// Float Item

.float-box {
  top: 0px;

  z-index: 1000;

  &.right {
    right: 20px;
  }

  &.left {
  }

  > div {
    padding-left: 5px;
  }

  .float-item {
    width: 150px;
  }
}

//

.float-item {
  min-width: 150px;
  z-index: 4;
}

.checkListTable {
  .lastColumn {
    .form-control {
      width: 50px;
    }

    .multiSelect {
      min-width: 125px;
      flex-grow: 1;
    }
  }
}

//

.table {
  &.simple {
    thead {
      tr:nth-child(1) {
        display: block !important;
      }
    }
  }
}

//

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker__input-container {
  .form-control {
    border: 0px;
    border-bottom: 1px solid #414042;
    border-radius: 0px;
  }

  input {
    border: 0px;
    border-bottom: 1px solid;
  }
}

//
.date-time-input {
}

.hide-vertical-scroll {
  overflow: auto;
  overflow-x: hidden;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: auto;
}

.overflow-y {
  overflow-y: auto;
}

//

.float-bottom-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

//

.main-profile-name {
  font-weight: bold;
}

.display-none {
  display: none !important;
}

// Editor Field
.rdw-list-dropdown {
  z-index: 0 !important;
}

//

.post-filter-button {
  position: absolute;
  right: 15px;
  top: 5px;
  z-index: 2;
}

//
.printTextArea {
  white-space: pre-wrap;
}

// modal sidebar
.modal-sidebar {
  height: 87vh;
  overflow: auto;
  background: #fff;
  border-right: 1px solid kt-state-color(wb-light-grey);

  .card {
    border: 0px;
  }

  .card-body {
    padding: 0px;
  }

  .contact-avatar {
    text-align: center;

    img {
      float: none;
      margin: 0 auto;
      margin-left: 25%;
      width: 50%;
      height: 50%;
      -webkit-border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      border-radius: 50% !important;
    }
  }

  .card {
    border: 0px;
  }

  .card-body {
    padding: 0px;
  }

  .contact-avatar {
    text-align: center;

    img {
      float: none;
      margin: 0 auto;
      margin-left: 25%;
      width: 50%;
      height: 50%;
      min-width: 150px;
      min-height: 150px;
      -webkit-border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      border-radius: 50% !important;
    }
  }
}

//   contact-modal

.contact-modal {
  .single-tab-body {
    height: 82.4vh;
    overflow: auto;
  }
}

// hover View

.hover-view {
  visibility: hidden;

  &:hover {
    visibility: visible;
  }
}

.hover-opacity {
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.anniversaries {
  .img-fluid {
    width: 100px;
    height: 100px;
    max-width: unset;
  }
}

// NewsPage

.news-page {
  padding: 1.5rem;
  color: black;
}

.news-page-scroll {
  height: 85vh;
  overflow: auto;
}

//
.page-drawer-body {
  height: 75vh;
  overflow-y: auto;
}

.email-view {
  .DraftEditor-root {
    height: 50vh;
    border: 1px solid #f1f1f1;
    padding: 2rem;
    overflow: auto;
  }
}

.door-knock-button {
  margin: 5px;
  height: 25px;
  padding-top: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  background-color: white !important;
  color: black !important;
  border: 1px solid !important;
  border-color: lightblue !important;
}

.videoWrapper {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.duplicate-contact {
  background-color: kt-state-color(wb-dark-blue) !important;
}

.rdw-emoji-modal {
  right: 15px;
  left: unset;
}

.userPopOver {
  width: 276px;

  .contact-edit-icon {
    top: 7px;
    right: 0px;
    position: absolute;
  }
}

// login

.login .btn {
  padding: 8px !important;
  font-size: 14px;
  font-weight: 800;
}

.kt-quick-search__input:focus ~ .quick-search-icon {
  color: black;
}

// table
.thead-bold {
  tr {
    th {
      font-weight: bold;
    }
  }
}

.top-performers {
  td {
    border-top: 0px;
    padding: 0.3rem;
  }
}

// Shadow

.shadow {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.hidden-icon {
  display: none;
}
.show-icon {
  margin-bottom: 10px;
}
.icon-play {
  font-size: 44px;
}

.invisible {
  opacity: 0 !important;
}
.card-video {
  &:hover {
    .image-preview {
      background: #acbec7b3;
      z-index: 200;
      .hidden-icon {
        display: block;
        padding-left: 8px;
        padding-right: 8px;
      }

      .image-preview-body {
        z-index: 9;
        visibility: visible;
      }

      .video-preview-body {
        z-index: 3;
        position: absolute;
        left: 38%;
        top: 49%;
        visibility: hidden;
      }
    }
  }
}

.image-preview {
  &:hover {
    background: #aab3f340;

    .image-preview-body {
      z-index: 9;
      visibility: visible;
    }

    .video-preview-body {
      z-index: 3;
      position: absolute;
      left: 38%;
      top: 49%;
      visibility: hidden;
    }
  }
}

.video-size {
  width: 230px !important;
  height: 130px !important;
  background-color: black;
}
.video-size-lp {
  width: 230px !important;
  height: 130px !important;
  background-color: black;
  object-fit: cover !important;
  aspect-ratio: 3 / 2 !important;
}

.small-icons-video {
  font-size: 14px;
}
.icons-player-video {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.play-box {
  z-index: 0 !important;
  opacity: 0 !important;
  display: none !important;
}

.icon-container-video {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.icon-image-preview {
  margin: 48px auto 6px auto;
  display: flex !important;
  flex-direction: column;
}

.image-preview-head {
  z-index: 99;
  width: 20px;
}

.image-preview-body {
  visibility: hidden;
}

// Social feed card

.social-feed-card-action {
  .icon-btn {
    font-size: 1.25rem;
  }
}

.equal-tab {
  margin-bottom: 1rem;
}

.scrapPaperTable {
  tr td:last-child {
    padding-right: 2rem !important;
  }
}

.p-left-2 {
  padding-left: 2rem !important;
}

.p-right-2 {
  padding-right: 2rem !important;
}

// my-profile
.my-profile {
}

.social-share button {
  padding-left: 7px;
}

.social-share {
  .pointer {
    cursor: pointer;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.network-results-dropdown {
  position: absolute;
  top: 0px;
  right: 5%;
}

.scrap-paper-body {
  height: 30vh;
  overflow: auto;
}

.nav-icon {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  cursor: pointer;
  background: transparent;
  transition: all 0.3s;
  border-radius: 4px;
}

.socialFeed {
  .MuiPaper-elevation1 {
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  }

  .MuiPaper-root {
    background-color: #fff !important;
    box-shadow: unset !important;
    z-index: 10000;
  }
}

.profile-image-container {
  position: relative;
  display: flex;
  justify-content: center;

  .mask {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 150px;
    // height: 150px;
    height: 100%;
    width: 100%;
    max-height: 150px;
    background-image: url("https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/overlays/Profile-Image-Overlay.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .service-Mask {
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
    border-radius: 0% !important;
    -webkit-border-radius: 0% !important;
  }

  .height-width-200 {
    height: 200px;
    width: 200px;
  }

  &:hover {
    .profile-image {
      opacity: 0.3;
    }

    .mask {
      opacity: 1;
    }
  }
}

.profile-image {
  max-width: 150px;
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  border: 2px solid #fff;
  -moz-box-shadow: 0px 6px 5px #ccc;
  -webkit-box-shadow: 0px 6px 5px #ccc;
  box-shadow: 0px 6px 5px #ccc;
  transition: all 0.4s ease-in-out;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.m-left-auto {
  margin-left: auto;
}

.m-right-auto {
  margin-right: auto;
}

// single Address
.single-address-icon {
  margin-top: auto;
  font-size: 1.5rem;
  padding-left: 10px;
}

.note-list.card {
  padding-top: 10px;
  height: 60vh;
  overflow: auto;

  .note-item {
    background: #ffffff4d;
    /* margin-bottom: 5px; */
    //   padding: 10px;
    padding-left: 1.15rem;
    border-left: 5px solid royalblue;

    /* border-left: 5px solid; */
    p {
      margin-bottom: 0px !important;
    }

    .kt-svg-icon {
      height: 15px;
      width: 15px;
    }
  }

  hr {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.timeline-popover {
  max-width: unset;

  .popover-body {
    min-width: 275px;
    max-width: 300px;
  }
}

.timeline-dropdown {
  .multiSelect__control {
    max-height: unset;
    max-width: 300px;
  }
}

.add-to-email {
  .textAreaEmoji-wrapper {
    border: unset;
    border: 1px solid #a7a9ac;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  .rdw-editor-toolbar {
    border: unset;
    padding-bottom: 6px;
    border-radius: 0px;
    border-bottom: 1px solid #a7a9ac;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  .textAreaEmoji-editor {
    padding: 1rem;
    height: 30vh;
    overflow: auto;
  }
}

.sms-form {
  textarea {
    height: 475px;
    padding: 1rem;
    border: 1px solid #a7a9ac;
  }

  .MuiInput-multiline {
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}

input[type="month"]::before {
  color: #999999;
  content: attr(placeholder);
}

input[type="month"] {
  color: #ffffff;
}

input[type="month"]:focus,
input[type="month"]:valid {
  color: #666666;
}

input[type="month"]:focus::before,
input[type="month"]:valid::before {
  content: "" !important;
}

.sidebar-preferences {
  .btn {
    padding: 0.65rem 0.5rem;
  }
}

.news-social-drawer {
  .MuiDrawer-paper {
    right: -28px;
  }
}

.toggle-button-group {
  .Mui-selected {
    .MuiToggleButton-label {
      font-weight: bold;
    }
  }
}

.small-input {
  height: 21px;
  padding: 0px;
  margin-right: 0.5rem;
  text-align: center;
}

.textarea-output {
  white-space: pre-wrap;
}

.font-size-tablet-text p span {
  font-size: 13px !important;
}
.tableFixHead tbody p {
  margin: 0 !important;
}

.include_space {
  white-space: break-spaces;
}

.sortable-table-body tr:last-child {
  display: none;
}

.text-capitalize {
  text-transform: lowercase !important;
  display: inline-block;
}

.text-capitalize::first-line {
  text-transform: capitalize;
}

.table-notification {
  table,
  thead,
  tr,
  tbody,
  th,
  td {
    text-align: center;
  }

  td:first-child {
    text-align: left;
  }
}

.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

.blue-short-chip {
  border-color: lightBlue;
  width: 10rem;
  height: 5rem;
}

//

.display-image-width {
  width: 310px;
  height: 180px;
}

.width-200 {
  width: 200px;
}

.wb-progress-bar {
  height: 2rem;
  font-size: 1.25rem;
  font-weight: bold;

  small {
    top: 15%;
  }
}

.top-auto {
  top: auto !important;
}

.widget-drag {
  width: 300px;
  height: 80px;
  border: 1px solid grey;
  border-radius: 8px;
  margin-bottom: 2px;
  background-color: white;
  padding: 2px;
  box-shadow: 0 0 5px #eee;
}

.drag {
  top: auto !important;
  left: auto !important;

  .drag-item {
    padding-bottom: 0px;
    margin: auto;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.rbc-event-label {
  display: none;
}

.rbc-row-segment {
  padding: 0 1px 0px 4px;
}

.rbc-event {
  min-height: 2.4rem !important;
  border: 0px !important;
  background-color: transparent !important;
  padding: 0px 0px;

  .rbc-event-content {
    color: black;
  }
}

.small-label-text {
  label {
    font-size: 0.75rem;
  }
}

.role-multi-row {
  display: contents;

  th {
    padding-left: 2rem !important;
  }
}

// Price plan

.price-sec-wrap {
  width: 100%;
  float: left;
  padding: 60px 0;
  font-family: "Lato", sans-serif;
}

.main-heading {
  text-align: center;
  font-weight: 600;
  padding-bottom: 15px;
  position: relative;
  text-transform: capitalize;
  font-size: 24px;
  margin-bottom: 25px;
}

.price-box {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.price-box ul {
  padding: 10px 0px 30px;
  margin: 17px 0 0 0;
  list-style: none;
  border-top: solid 1px #e9e9e9;
}

.price-box ul li {
  padding: 7px 0;
  font-size: 14px;
  color: #808080;
}

.price-box ul li .fas {
  color: #68ae4a;
  margin-right: 7px;
  font-size: 12px;
}

.price-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.34;
  margin-bottom: 0;
  padding: 6px 15px;
  display: inline-block;
  border-radius: 3px;
}

.price-label.basic {
  background: #e8eaf6;
  color: #3f51b5;
}

.price-label.value {
  background: #e8f5e9;
  color: #4caf50;
}

.price-label.premium {
  background: #fbe9e7;
  color: #ff5722;
}

.price {
  font-size: 44px;
  line-height: 44px;
  margin: 15px 0 6px;
  font-weight: 900;
}

.price-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.67;
  color: inherit;
  width: 100%;
  margin: 0;
  color: #989898;
}

.plan-btn {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  padding: 11px 30px;
  border: 2px solid #b3b3b3;
  color: #000;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0;
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.plan-btn::after {
  position: absolute;
  left: -100%;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #000;
  z-index: -1;
  transition: all 0.35s ease-in-out;
}

.plan-btn:hover::after {
  left: 0;
}

.plan-btn:hover,
.plan-btn:focus {
  text-decoration: none;
  color: #fff;
  border: 2px solid #000;
}

@media (max-width: 991px) {
  .price-box {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .main-heading {
    font-size: 21px;
  }

  .price-box {
    margin-bottom: 20px;
  }
}

.selected-package {
  border: 3px solid green;
}

//

.rbc-agenda-table {
  tr:first-child {
    th:first-child {
      display: none;
    }

    td:first-child {
      display: none;
    }
  }

  // tr {
  //     td:first-child {
  //         display: none;
  //     }
  // }
}

.sms-view {
  .cursor-hand {
    font-weight: 600;

    &:hover {
      color: #822433;
    }
  }
}

.cursor-hand {
  &:hover {
    cursor: pointer;
  }
}

.opacity-section-advertising-packages {
  color: #d1d1d1 !important;
}

.checkbox-advertising-item {
  display: flex;
  align-items: center;
}
.text-advertising-item {
  vertical-align: middle !important;
  width: 100% !important;
}

.enquiry-float-button {
  display: flow-root;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.hide {
  visibility: hidden;
}

.kt-svg-icon {
  max-width: 22px;
  max-height: 22px;
}

.table-center-align {
  th {
    text-align: center;
  }

  td {
    text-align: center;
  }
}

.drop-zone-with-cropper .ant-upload.ant-upload-select-picture-card {
  text-align: center;
  cursor: pointer;
  border: 2px dashed kt-base-color(grey, 2);
  width: 100%;
}

.home-page-setup,
.testimonial-form,
.blog-form {
  .react-toggle-track-check {
    left: 15px;
  }

  .react-toggle-track-x {
    right: 15px;
  }

  .react-toggle-thumb {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 3px;
    box-shadow: none;
  }

  .home-button {
    .react-toggle-track {
      width: 85px !important;
      font-size: 12px !important;
      border-radius: 10px !important;
    }

    .react-toggle--checked {
      .react-toggle-thumb {
        left: 60px;
      }
    }
  }

  .home-opening-hours {
    .react-toggle-track {
      width: 95px !important;
      font-size: 12px !important;
      border-radius: 10px !important;
    }

    .react-toggle--checked {
      .react-toggle-thumb {
        left: 73px;
      }
    }
  }

  
}

.textarea-community {
  .MuiInputBase-inputMultiline{
    height: 100px !important;
  }
} 



.count-avatar {
  background-color: #c0d9e6 !important;
  color: #000000 !important;
  font-weight: 600;
}

.char-counter {
  font-size: 12px; 
  position: absolute;
  right: 9px;
  bottom: -41px;
  transition: color 0.3s ease;
}

.text-top {
  text-align: center;
  margin-top: 15rem;
  font-size: 20px;
  font-style: italic;
  color: rgb(185, 180, 180);
}

.testimonial-avatar {
  .MuiAvatar-root {
    width: 25px !important;
    height: 25px !important;
  }

  .MuiAvatar-colorDefault {
    background-color: #bddff6;
  }
}

.blog-form {
  .editorMarketing .DraftEditor-root {
    height: 35vh !important;
  }
}

// .green-button{
//   color: #2bb673;
// }
// .lightGrey-button{
//   color: #808080;
// }
.serch-field-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.testimonial-avatar {
  .MuiAvatar-colorDefault {
    color: #414042 !important;
    background-color: #c1dae6 !important;
    font-weight: bold;
  }

  .MuiAvatar-root {
    width: 30px !important;
    height: 30px !important;
    font-size: 10px;
  }

  .pr-2 {
    padding: 0 !important;
  }
}

.image-number {
  background: #7b7d7e;
  color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  right: 37px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-email-table {
  .row {
    // background-color: transparent !important;
    display: inline-table;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.glqWOv:hover:enabled,
.glqWOv:focus:enabled {
  background-color: #822433 !important;
}

.glqWOv,
.kwUGKV {
  font-size: 1.4em !important;
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  line-height: normal !important;
}

.pointer-events-none {
  cursor: not-allowed !important;
}

.rdw-link-modal,
.rdw-embedded-modal {
  height: auto;
}

.under-line-icon img {
  width: 19px;
  margin-top: 2px;
}

.listing-popover-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 350px;
  padding: 10px 0px;
}

// .multi-media-text-editor {
//   .rdw-option-wrapper {
//     width: 30px !important;
//     height: 30px !important;
//   }
// }
.scroll-hide {
  .MuiInputBase-input::-webkit-scrollbar {
    display: none;
  }
}

.pointer-none {
  pointer-events: none;
}

.textAreaEmoji {
  .public-DraftStyleDefault-block {
    margin: 0.5em 0;
  }
}

.card-text-editor {
  min-height: 150px !important;
}

.copy-existing-filter {
  label {
    margin-bottom: 0;
  }
}

.placeholder-f-size .MuiInputBase-input {
  font-size: 10px;
}

.disable-prospect-header-btn {
  opacity: 0.4 !important;
  pointer-events: none;
}

.balance-due {
  .MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .MuiInputBase-input.Mui-disabled {
    color: #414042 !important;
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.expand-All {
  white-space: nowrap !important;
}

.green-border {
  border: 5px solid #2bb673;
}

.mail-list-name {
  .font-weight-light-bold {
    font-weight: 400 !important;
  }

  .mail-list-import-btn {
    background-color: #262626 !important;
  }

  .mail-list-text-field {
    height: 200px !important;
    width: 300px !important;
  }

  .mail-list-table {
    table {
      border: solid 1px black !important;
    }

    td,
    th {
      border-right: solid 1px black !important;
    }

    th {
      min-width: 130px;
      padding-left: 5px;
    }

    th:last-child {
      min-width: 170px;
      padding-left: 5px;
    }

    td {
      padding-left: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

// Marketing/Social Media
#update {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: 20px;
}

.template-body {
  width: 100%;
  height: 100%;
  padding: 30px 0px;
  background: #ebebeb;
  border: 1px solid #aa9f9f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-body2 {
  width: 100%;
  height: 700px;
  padding: 30px 0px;
  background: #ebebeb;
  border: 1px solid #aa9f9f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-accordian {
  overflow-y: auto;
  overflow-x: hidden;
  height: 67vh;
}

.poster-template {
  .node-content-item {
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
  }

  // .node-content-item:hover {
  //   border: 1px solid #009400 !important;
  // }
  .node-content-item.removeBorder {
    border: 1px solid transparent !important;
  }

  .node-content-item:hover {
    border: 1px solid #009400;
  }

  .open-home-node,
  .auction-node {
    width: 71%;

    .address {
      text-transform: capitalize;
    }
  }
}

.poster-template-customize {
  .accordian-block {
    position: relative;
    height: 9rem;
    width: 9rem;
    cursor: pointer;
  }

  .empty-container {
    height: 344px;
    width: 250px;
  }

  .img__img {
    width: 100%;
    height: 100%;
  }

  .img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(69, 69, 70, 0.5);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .accordian-block:hover {
    .img__description_layer {
      visibility: visible;
      opacity: 1;
    }
  }

  .img__description {
    transition: 0.2s;
    text-align: center;
    font-size: 9px;
    font-weight: bold;
    padding: 20px;
  }
}

// list disable
.list-disable {
  // pointer-events: none;
  color: #95a1ab;
  background: #e1e1e1;
}

.poster-template-edit {
  .edit-card-header {
    font-size: 15px;
    font-weight: bold;
  }

  .img__wrap {
    height: 8rem;
    width: 17rem;
    cursor: pointer;
  }
}

// antd image upload

.antd-image-upload-with-crop {
  .ant-upload.ant-upload-select-picture-card {
    width: auto;
    height: auto;
    border: none;
  }

  .image-edit {
    width: 100%;
    height: inherit;
  }

  .profile {
    .ant-upload.ant-upload-select-picture-card {
      border-radius: 50%;
    }

    .image-icon-preview .preview-icon {
      border-radius: 50%;
    }

    .image-edit {
      border-radius: 50% !important;
    }
  }

  .image-icon-preview {
    position: relative;

    .preview-icon {
      display: none;
      position: absolute;
      background-color: #00000066;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      svg {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  .image-icon-preview:hover {
    .preview-icon {
      display: flex;
    }
  }
}

.ant-modal-wrap {
  z-index: 1100;
}

// antd-customise

.ant-input:not(.ant-customize-input) {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #8d8d8d;
  padding-left: 0px;
  font-size: 13px !important;
}

.ant-input:hover {
  border-color: #8d8d8d;
}

.ant-input:focus {
  border-color: #8d8d8d;
  box-shadow: none;
}

.ant-input-number:not(.ant-customize-input) {
  border-bottom: 1px solid #8d8d8d;
  border-top: none;
  border-right: none;
  border-left: none;
}

.ant-input-number:hover,
.ant-input-number:focus {
  border-color: #8d8d8d;
  box-shadow: none;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-bottom: 1px solid #8d8d8d;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-left: 0px;
  }
}

.ant-select-focused:not(.ant-select-disable).ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: #8d8d8d;
    box-shadow: none;
  }
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 7px;
  color: #b0b1b3;
  font-size: 11px;
}

.ant-form-item-control-input {
  min-height: 0 !important;
}

.ant-form-item-label {
  text-align: initial !important;
}

.ant-form-item-control {
  width: 100% !important;
}

.ant-form-item {
  margin-bottom: 15px !important;
}

.ant-select-single {
  .ant-select-selector {
    .ant-select-selection-search {
      left: 0px;
    }
  }
}

.ant-popover,
.ant-select-dropdown {
  z-index: 3001 !important;
}

.MuiButtonBase-root.Mui-disabled {
  pointer-events: auto !important;
}

.feild-margin-top {
  margin-top: 16px;
}

.check-icon-color {
  color: #009400;
}

.instant-sms-view {
  .cursor-hand {
    font-weight: 600;

    &:hover {
      color: #822433;
    }
  }
}

.price-finder-market-details {
  table {
    border-collapse: collapse;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}

.disabled-text {
  opacity: 0.5;
}

.text-black {
  color: black !important;
}

// Sweet alert

.swal2-radio {
  padding-top: 1rem;
  flex-direction: column;
  align-items: baseline;
}

.send-modal-content-wrapper .DraftEditor-editorContainer figure {
  pointer-events: none;
}

.sms-agent-select .select-agent {
  width: 150px !important;
  border: none;
  padding: 5px;
  outline: 0px;
  border-bottom: 1px solid gray;
}

.custom-showMore-modal {
  .custom-showMore-text {
    padding: 5px;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000000;
  }

  .show-more-text-capitalize {
    text-transform: lowercase !important;
  }

  .show-more-text-capitalize::first-line {
    text-transform: capitalize !important;
  }
}

.address-alternative-text {
  background: #822433 !important;
  // color: #822433 !important;
  padding: 3px 8px !important;
}

.listings-files-popover {
  z-index: 1 !important;
}

#social_media_fix_OverFlow {
  overflow: scroll !important;
}
.open-home-block-1 {
  font-size: 16px !important;
  top: 130px !important;
}
#getOpenHomeTemplate1 {
  font-size: 16px !important;
  top: 130px !important;
}
#getOpenHomeTemplate2 {
  top: 130px !important;
}
#getOpenHomeTemplate3 {
  top: 120px !important;
}
#getOpenHomeTemplate4 {
  top: 100px !important;
}
@media (min-width: 2560px) {
  #social_media_fix_OverFlow {
    overflow: scroll !important;
  }
  .open-home-block-1 {
    font-size: 16px !important;
    top: 130px !important;
  }
  .style-3 {
    margin-top: -14% !important;
  }

  // .big-laptop{
  //   top: 2.8vw !important
  // }

  // .style_3_4{
  //   font-size :0.6vw !important;
  //   padding-left: 1rem;
  // }
}

@media only screen and (min-width: 1800px) and (max-width: 2559px) {
  .open-home-block-1 {
    font-size: 16px !important;
    top: 130px !important;
  }
  .style-3 {
    margin-top: -14% !important;
  }

  .big-laptop {
    top: 2.8vw !important;
  }

  .style_3_4 {
    font-size: 16px !important;
  }
  // #getOpenHomeTemplate2{
  //   top: 22.5% !important
  // }
  // #getOpenHomeTemplate3{
  //   top: 22.5% !important
  // }
  // #getOpenHomeTemplate4{
  //   top: 10% !important
  // }
}

@media (max-width: 1200px) {
  #getOpenHomeTemplate3 {
    top: 80px !important;
  }
  .poster-template {
    .bg-image-node {
      // width: 400px !important;
      // height: 400px !important;
    }

    .address-feature-block-1 {
      left: 13px !important;
      bottom: 0px !important;
      font-size: 13px !important;
      justify-content: unset !important;
      height: 50px !important;

      .feature-icon {
        width: 13px !important;
      }

      .features-node {
        justify-content: unset !important;
        width: auto !important;
      }
    }

    .address-feature-block-2 {
      bottom: 110px !important;
      font-size: 18px !important;
      height: 70px !important;

      .feature-icon {
        width: 17px !important;
        margin-bottom: 5px !important;
      }

      .features-node {
        width: 125px !important;
      }
    }

    .open-home-block-1 {
      .main-heading-block {
        padding: 5px 0px 20px !important;
        margin-top: 1% !important;
      }

      .new-class {
        margin-top: 45px;
      }

      .layout2 {
        margin-top: -5% !important;
        padding-bottom: 0 !important;
      }
      .small-laptop {
        // left: 200px !important;
        top: 80px !important;
        // margin-top: 7% !important;
      }
      .main-heading-node {
        font-size: 28px !important;
      }

      top: 130px !important;
      font-size: 16px !important;
    }

    .testimonial-block {
      top: 200px !important;
      font-size: 19px !important;
    }

    .agent-profile-block-1 {
      bottom: 55px !important;
      margin-left: 35px !important;

      .agent-profile-name {
        font-size: 22px !important;
      }

      .agent-profile-desc {
        margin-top: 5px !important;
        font-size: 9px !important;
      }
    }

    .agent-profile-block-2 {
      bottom: 45px !important;
      margin-left: 35px !important;

      .agent-profile-name {
        font-size: 22px !important;
      }

      .agent-profile-desc {
        margin-top: 5px !important;
        font-size: 9px !important;

        .agent-mobile {
          margin-top: 5px !important;

          svg {
            width: 12px !important;
            margin-right: 8px !important;
          }
        }
      }
    }

    .agent-profile-block-3 {
      top: 140px !important;
      margin-left: 12px !important;

      .agent-profile-name {
        font-size: 19px !important;
      }

      .agent-profile-desc {
        margin-top: 10px !important;
        font-size: 9px !important;
        height: 70px !important;

        .agent-mobile {
          font-size: 11px !important;

          svg {
            width: 12px !important;
            margin-right: 8px !important;
          }
        }
      }
    }
  }

  .poster-template-customize {
    .accordian-block {
      height: 5.5rem !important;
      width: 5.5rem !important;
    }
  }

  .image-icon-preview {
    width: 300px !important;
    height: 200px !important;
  }

  .poster-template-edit {
    .img__wrap {
      height: 7rem !important;
      width: 6rem !important;
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .poster-template-edit {
    .img__wrap {
      height: 6rem !important;
      width: 8rem !important;
    }
  }
}
@media only screen and (min-width: 1700px) and (max-width: 1800px) {
  .poster-template-edit {
    .img__wrap {
      height: 8rem !important;
      width: 15rem !important;
    }
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .poster-template-edit {
    .img__wrap {
      height: 8rem !important;
      width: 11rem !important;
    }
  }

  .open-home-block-1 {
    .small-laptop {
      margin-top: 1% !important;
      // top: 5px !important
    }
    .main-heading-block {
      padding: 10px 0px 10px !important;
      // margin-top: 8% !important;
    }
    .addmore {
      margin-top: 10px;
    }
  }
}

@media (max-width: 950px) {
  .poster-template {
    .bg-image-node {
      // width: 275px !important;
      // height: 275px !important;
    }

    .address-feature-block-1 {
      left: 10px !important;
      bottom: 3px !important;
      font-size: 10px !important;
      height: auto !important;

      .feature-icon {
        width: 10px !important;
      }

      .features-node {
        justify-content: unset !important;
        width: auto !important;
      }
    }

    .address-feature-block-2 {
      bottom: 105px !important;
      font-size: 12px !important;
      height: 22px !important;

      .feature-icon {
        width: 12px !important;
        margin-bottom: 5px !important;
      }

      .features-node {
        width: 80px !important;
      }
    }

    .open-home-block-1 {
      .main-heading-block {
        padding: 10px 0px 10px !important;
      }

      .main-heading-node {
        font-size: 18px !important;
      }

      .auction-node {
        font-size: 8px !important;
      }
      .open-home-node {
        font-size: 16px !important;
      }
      top: 130px !important;
      font-size: 16px !important;
      // height: 85% !important;
    }

    .testimonial-block {
      top: 135px !important;
      font-size: 13px !important;
    }

    .agent-profile-block-1 {
      bottom: 37px !important;
      margin-left: 25px !important;

      .agent-profile-name {
        font-size: 15px !important;
      }

      .agent-profile-desc {
        margin-top: 3px !important;
        font-size: 6.5px !important;
      }
    }

    .agent-profile-block-2 {
      bottom: 30px !important;
      margin-left: 25px !important;

      .agent-profile-name {
        font-size: 15px !important;
      }

      .agent-profile-desc {
        margin-top: 3px !important;
        font-size: 6px !important;

        .agent-mobile {
          margin-top: 3px !important;

          svg {
            width: 10px !important;
            margin-right: 4px !important;
          }
        }
      }
    }

    .agent-profile-block-3 {
      top: 90px !important;
      margin-left: 8px !important;

      .agent-profile-name {
        font-size: 13px !important;
      }

      .agent-profile-desc {
        margin-top: 10px !important;
        font-size: 7px !important;
        height: 50px !important;

        .agent-mobile {
          font-size: 8px !important;

          svg {
            width: 10px !important;
            margin-right: 5px !important;
          }
        }
      }
    }
  }

  .image-icon-preview {
    width: 300px !important;
    height: 200px !important;
  }

  .poster-template-edit {
    .img__wrap {
      height: 4rem !important;
      width: 4rem !important;
    }
  }
}

// @media (max-width: 768px){
// }

// @media (max-width: 640px){
// }
.theme-table-striped {
  .notes-table-field {
    max-width: 10rem !important;
    word-break: break-all !important;
    min-width: 10rem !important;
  }

  .contact-table-field {
    max-width: 10rem !important;
    word-break: break-all !important;
  }
}

.bold-text {
  font-weight: bold;
  color: black;
}

.f-17 {
  font-size: 16px;
}

.filter-saturate {
  filter: saturate(0.2);
}

.character-circle .popover .arrow::before,
.popover .arrow::after {
  border-top-color: #707070 !important;
}

.enquiry-settings {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.enquiry-settings .MuiInputBase-input {
  padding: 0px;
  text-align: center;
}

// activity log setting modal

.activity-log .modal-dialog .modal-xl {
  .modal-content {
    border-radius: 4px;
    height: 90% !important;
  }
}

.enquiry-log .theme-table-striped tbody tr {
  height: 49px !important;
}

.enquiry_log_all .MuiCheckbox-colorSecondary.Mui-disabled {
  color: #d81b60;
}

.scheduler .ant-picker-ranges .ant-picker-ok button:not(:disabled) {
  background-color: #2bb673;
  color: white;
}

@media (max-width: 1400px) {
  .scheduleText {
    font-size: 13px !important;
  }
}

.scheduleText {
  font-size: 18px;
}
.scheduler .ant-picker-time-panel-column::after {
  height: 0px;
}
.templateRadioBtn {
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #c0d9e6;
    background-color: #f0fafe;
    color: #000000;
  }
  .ant-radio-group-solid .ant-radio-button-checked {
    background-color: #c0d9e6;
    border-color: #c0d9e6;
    color: #000;
  }
  .ant-radio-button-wrapper {
    width: 100px;
    background-color: #f0f9fe;
    border-color: #f0f9fe;
    text-align: center;
    color: #000;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent;
  }
}

div.grid-item-information:nth-child(even) {
  background-color: kt-state-color(wb-light-blue);
}

.filter-toggle .MuiToggleButtonGroup-grouped {
  border: 0px !important;
}

.swal-alert-wide-similar-contacts {
  width: 640px !important;
  // max-height: 50%; /* To be adjusted as you like */
  overflow-y: scroll;
}
