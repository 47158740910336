.socialIcons{
    font-size: 20px;
    a {
      color: #666;
      text-decoration: none;
      padding: 0 5px 0 0;
      
      &.github:hover {
        color: #24292e;
      }
      &.twitter:hover {
        color: #1da1f2;
      }
      &.facebook:hover {
        color: #3b5998;
      }
    }
}
.userPopOver {
  p {
    margin-bottom: .1rem;
  }
}

.avatar {
    
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    top: 30px;
    left: 20px;
  }
}

.member-popover {
  .avatar {
    img {
      width: 75px;
      height: 75px;

      top: 30px;
      left: 20px;
    }
}
}
.profileDetails {
  .characterCircle {
    padding: 3px;
}
  .MuiAvatar-circle {
    width: 25px;
    height: 25px;
    font-size: 1rem;
    margin-right: .5rem;
  }
}