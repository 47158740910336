//
// Popover
//



// Base
.popover {
    box-shadow: $popover-box-shadow;
    border: 0px;


    .popover-header {
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 1px solid kt-base-color(grey, 2);
    }
}

#popover-listing {
    max-width: 100%;
    top: 40px !important
}

#popover-property-features {
    box-shadow: $popover-box-shadow;
    border: 0px;
    height: 250px;
    overflow: auto;
    max-width: 800px;
    width: 100%;
    position: relative;


    .popover-header {
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 1px solid kt-base-color(grey, 2);
    }
}

#popover-basic-sms {
    box-shadow: $popover-box-shadow;
    border: 0px;
    height: 250px;
    overflow: auto;
    max-width: 400px;
    width: 100%;
    position: relative;


    .popover-header {
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 1px solid kt-base-color(grey, 2);
    }
}