.into_typo{
    color: white !important;
    font-weight: 500 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    text-align: justify;
    font-family: "Open Sans" !important;
    font-size: 1.2rem !important;

}
.text_white{
    color: white;
}

.p-break{
    white-space: break-spaces !important;
}
.main_container{
    max-width: 1080px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.preview_microsites{
    background:  linear-gradient(157deg, rgba(164,0,19,1) 0%, rgba(106,25,50,1) 100%);
    justify-content: center;
    horiz-align: center;
    width: 100%;
    align-items: center;
}
.show_more_box{
    max-height: 390px;
    overflow-x: hidden;
    overflow-y: auto;
}

.settings-form .MuiTypography-colorSecondary{
    color: rgba(106,25,50,1);

}

.info_box{
    max-width: 600px;
    margin-left:auto;
    margin-right: auto;
}
.adderess_typo {
    margin-top: 30px !important;
    margin-left: 20px !important;
    left: 0;
    color: white;
    font-size: 3.25rem !important;
    font-family: Cormorant Garamond !important;
    font-weight: 700 !important;
    letter-spacing: -2px !important;
    text-transform: capitalize;
}

.sub_adderess_typo {
    margin-left: 20px !important;
    left: 0;
    color: white;
    font-size:1.55rem !important;
    font-family: 'Open sans' !important;
    font-weight: 200 !important;
    text-transform: capitalize;

}
.sub_header{
    font-weight: 400;
    text-align: end;
    white-space: pre-line;
    font-size: 1.2rem;
}
.textWhite {
    color: white;
}
.welook_typo{
    font-family: 'Open sans' !important;
    font-weight: 200 !important;
    font-size:1.55rem !important;
    color: white;
}

.time_typo{
    font-family: 'Open sans' !important;
    font-weight: 700 !important;
    font-size: large !important;
    color: white;
}

.letter_from_typo{
    font-size: 3.25rem !important;
    font-family: Cormorant Garamond !important;
    font-weight: 700 !important;
    letter-spacing: -2px !important;
    color: white;
    margin-left: 0;
    text-align: right !important;
    margin-bottom: auto !important;
}
.letter_back{
    min-height: 200px !important;
    background: white !important;
    border-radius: 15px !important;
    width: 350px;
    min-width: 350px;

}
.secondary_letter_back{
    min-height: 200px !important;
    background: transparent !important;
    color: white !important
}

.secondary_letter_typo{
    padding: 25px;
    text-align: justify;
    font-family: 'Open sans' !important;
    font-weight: 500 !important;
    width: 100% !important;
    max-width: 100% !important;
    line-break: auto !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 20;
    font-size: 1.2rem !important;

}
.letter_box{
    margin-right: 20px;
    letter-spacing: -2px;
    margin-left: 0;
}
.add_contact{
    color: white !important;
    border-color: white !important;
    font-size: 1.1rem !important;
    font-weight: 700;
    border-radius: 50px !important;
    width: 200px;
    text-transform: none !important;
}

.letter_typo{
    padding: 25px;
    text-align: justify;
    font-family: 'Open sans' !important;
    font-weight: 400 !important;
    width: 100% !important;
    max-width: 350px !important;
    line-break: auto !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    font-size: 1.1rem !important;

}

.profile_image{
    border: white;
    max-width: 240px;
    height: 300px;
    border-style: solid;
    border-width: 1px;
    background: transparent;
    border-radius: 25px !important;
    margin-right: 40px;
    align-self: center;
    right: 0;
    margin-left: auto;

}

.profile_image .MuiCardActionArea-root{
    height: 100% !important;
    min-width: 200px;
}

.box-profile{
    align-items: center;
    display: flex;

}

.profile-back{
    border: white;
    border-style: solid;
    border-width: 1px 0px 1px 1px;
    background: transparent;
    border-radius: 25px !important;
    width: 50%;
    position: fixed;
    height: 370px;
    margin-left: 142px;
    margin-right: 0;

}

.co-profile-back{
    border: white;
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    background: transparent;
    border-radius: 25px !important;
    width: 80%;
    position: fixed;
    height: 370px;
    margin-left: 0;
    left: 0;
    margin-right: 400px;
    right: auto;

}
.bold{
    font-weight: bold;
    font-size: 2.75rem !important;
    font-family: Cormorant Garamond !important;
    font-weight: 700 !important;
    letter-spacing: -2px !important;
}

/*.box-profile {*/
/*    position: relative;*/
/*    overflow: hidden; !* Hides any content outside the Box container *!*/
/*}*/

/*.profile_image_card {*/
/*    width: 100%; !* Ensure the image stretches to fill the available width *!*/
/*    object-fit: cover; !* Maintain the image's aspect ratio *!*/
/*}*/

/*.box-profile::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    right: 0;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*    border-left: 2px solid #000; !* Change the color and size of the border as needed *!*/
/*    z-index: -1; !* Push the border behind the images *!*/
/*}*/

.coAgentDivider{
    background-color: rgba(255, 255, 255, 0.5) !important;
    height: 0.5px !important;
    width: 100%;
}
.listedBlockGrid{
    max-width: 600px;
}
@media (min-width: 2000px) {
    .co-profile-back {
        width: 75%
    }
}

@media (min-width: 1250px) {
    .co-profile-back {
        width: 75%
    }
}