.custom-dropdown {
   position: static !important;
    .dropdown-menu.show {
        z-index: 9999;
        top: 33px;
        width: max-content;
        padding: 1rem;

        label{
            padding-left: 1rem;;
        }
    }
}