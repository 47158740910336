.switch {
	position: relative;
	display: block;
	vertical-align: top;
	width: 100px;
	height: 30px;
	padding: 3px;
	margin: 0 10px 10px 0;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
	border-radius: 18px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	box-sizing: content-box;
}

.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing: content-box;
}

.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	box-sizing: content-box;
}

.switch-label:before,
.switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing: content-box;
}

.switch-label:before {
	content: attr(data-off);
	right: 11px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}

.switch-input:checked~.switch-label {
	background: #E1B42B;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-label:before {
	opacity: 0;
}

.switch-input:checked~.switch-label:after {
	opacity: 1;
}

.switch-handle {
	position: absolute;
	top: 4px;
	left: 4px;
	width: 28px;
	height: 28px;
	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
	background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
	width: 12px;
	height: 12px;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.switch-input:checked~.switch-handle {
	left: 74px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
========================== */
.switch-label,
.switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}

.switch-left-right .switch-label {
	overflow: hidden;
}

.switch-left-right .switch-label:before,
.switch-left-right .switch-label:after {
	width: 20px;
	height: 20px;
	top: 4px;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 11px 0 0 0;
	text-indent: -12px;
	border-radius: 20px;
	box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.switch-left-right .switch-label:before {
	background: #eceeef;
	text-align: left;
	padding-left: 80px;
}

.switch-left-right .switch-label:after {
	text-align: left;
	text-indent: 9px;
	background: #FF7F50;
	left: -100px;
	opacity: 1;
	width: 100%;
}

.switch-left-right .switch-input:checked~.switch-label:before {
	opacity: 1;
	left: 100px;
}

.switch-left-right .switch-input:checked~.switch-label:after {
	left: 0;
}

.switch-left-right .switch-input:checked~.switch-label {
	background: inherit;
}


.toggle-switch {
	// width: 100%;
	// height: calc(1.5em + 1.3rem + 2px);
	// padding: 1rem;
	/* font-size: 1rem; */
	/* font-weight: 400; */
	/* line-height: 1.5; */
	/* color: #495057; */
	/* background-color: #fff; */
	/* background-clip: padding-box; */
	/* border: 1px solid #e2e5ec; */
	/* border-radius: 4px; */
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	.react-toggle { 
		text-transform: uppercase;

	}
	.react-toggle-track {
		width: 60px;
		font-size: 10px;
		background-color: #A7A9AC;

	}

	.react-toggle-track-check {
		text-transform: uppercase;
		color: white;
		font-weight: bold;
		top: 10px;
		width: 20px;
		left: 8px;
	}

	.react-toggle-track-x {
		top: 10px;
		right: 5px;
		font-weight: bold;
		color: white;
		width: unset;
	}

	.react-toggle--checked {
		text-transform: uppercase;
		.react-toggle-track {
			width: 60px;
			font-size: 10px;
			background-color: #2BB673;
	
		}

		.react-toggle-thumb {
			left: 36px;

		}
	}


}