.badge-icon {
    .MuiBadge-anchorOriginTopRightRectangle {
        top: -4px;
        right: 0;
        transform: scale(1) translate(50%, -50%);
        transform-origin: 100% 0%;
    }
    .fas {
        color: #fff !important;
    }
    
    .MuiBadge-colorSecondary {
        color: #ffffff;
        background-color: #bb0a0a;
    }
    
    .MuiBadge-badge {
        height: 1rem;
        width: 1rem;
        min-width: 1rem;
        display: flex;
        padding: 0 7px;
        z-index: 1;
        position: absolute;
        flex-wrap: wrap;
        font-size: .75rem;
        box-sizing: border-box;
        transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        font-family: Poppins;
        font-weight: 500;
        font-size: .75rem;
        line-height: 1;
        align-content: center;
        border-radius: 22px;
        flex-direction: row;
        justify-content: center;
    }
}