//
// Timeline 5
//

// Variables
$timeline-5-label-width: 81px;
$timeline-5-bar-width: 40px;
$timeline-5-badge-size: 12px;
$timeline-5-bar-border-width: 4px;



.timeline.timeline-5 {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 85px;
		width: $timeline-5-bar-border-width;
		top: 0;
		bottom: 0;
		// background-color: $gray-200;
		background-color: #A7A9AC;
	}

	.timeline-item {
		display: flex;
        align-items: center;
		position: relative;
		margin-bottom: 1.7rem;

        &:last-child {
            margin-bottom: 0;
        }

		.timeline-label {
            width: $timeline-5-label-width;
            flex-shrink: 0;
			font-size: 1rem;
			font-weight: 500;
            position: relative;
			color: black;
		}

        .timeline-badge {
            position: relative;
            z-index: 1;
            display: block;
			width: $timeline-5-badge-size;
			height: $timeline-5-badge-size;
            border-radius: 100%;
            background-color: $gray-300;
            flex-shrink: 0;
            margin-right: 1.5rem;

            @each $name, $color in $theme-colors {
                &.timeline-badge-#{$name} {
                    background-color: $color;
                }
            }
        }

        .timeline-content {
            flex-grow: 1;
        }
	}
	.timeline-item.timeline-start-button {
		.timeline-badge
		{
		margin-right: 15px;
		flex-shrink: 0;
		background: white;
		width: 13px;
		height: auto;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
	  }
	  }
}
