// .contact-profileBody {
//   .app-bar {
//     box-shadow: unset;
// .MuiTabs-flexContainer {
//   border-bottom: 2px solid;
// }
//     button {
//       padding: 0px 0px;
//       min-height: 35px;
//       // border-bottom: 2px solid grey;
//     }
//   }

//   .timeline-body {
//     position: relative;
//     .timeline-dropdown {
//       position: absolute;
//       top: 0px;
//       min-width: 100px;
//       right: 0px;
//     }
//   }

//   .tab-content {
//     padding: 0px 10px 0px 15px;
//     height: 85vh;
//     overflow: auto;
//   }

// }

.contact-profileHeader {
  text-align: end;
}

/* Profile sidebar */
.contact-sidebar {
  height: 90vh;
  overflow: auto;
  background: #fff;
  border-right: 1px solid;

  .card {
    border: 0px;
  }

  .card-body {
    padding: 0px;
  }

  .contact-avatar {
    text-align: center;

    img {
      float: none;
      margin: 0 auto;
      margin-left: 25%;
      width: 50%;
      height: 50%;
      -webkit-border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      border-radius: 50% !important;
    }
  }
}

.contact-personalInfo {
  text-align: center;
  margin-top: 20px;
}

.contact-personalInfo-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  // margin-bottom: 7px;
}

.contact-personalInfo-job {
  text-transform: uppercase;
  color: #7c83aa;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.contact-socialButtons {
  text-align: center;
}

.contact-socialButtons button {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 5px;
  margin-right: 5px;
}

.contact-socialButtons button:last-child {
  margin-right: 0px;
}

/* Profile Content */
.contact-content {
  padding: 20px;
  background: #fff;
  min-height: 460px;
}

.contact-profileBasicInfo {
  margin-bottom: 10px;

  .row {
    margin-bottom: 3px;
    margin-top: 3px;
  }

  p{
    margin-bottom: 0px;
  }
}

.contact-connections {
  margin-bottom: 10px;

  .MuiList-root {
    padding-left: 0px;
  }
}

.contact-editButton {
  text-align: end;
  margin-bottom: -33px;
}

.note-timeline {

  .MuiTimelineItem-missingOppositeContent:before {
    display: none;
  }

}


.connections {
  .MuiAvatar-root {
    width: 20px;
    height: 20px;
    font-size: inherit;
  }

  .relation {
    // margin: auto;
  }
}

.note-list.card {
  padding-top: 10px;
  height: 75vh;

  .note-item {
    background: #ffffff4d;
    /* margin-bottom: 5px; */
    padding: 10px;
    border-left: 5px solid royalblue;

    /* border-left: 5px solid; */
    .kt-svg-icon {
      height: 15px;
      width: 15px;
    }
  }

}

.table-checkbox {
  padding: 0px !important;
}

.contactModalForm {
  .tabs {}
}

// Note Form
.note-form {
  position: relative;

  .dictaphone {
    right: 0px;
    top: 10px;
    position: absolute;

  }

  .add-note {
    position: relative;
    // top: 50px;
    right: 0px;
    padding: 6px;
  }
}

.contactsFiles-files {
  .card {
    .card-body {
      .file-type {
        i {
          font-size: 50px;
        }
      }
      button {
 font-size: 15px;
      }
    }
  }
}