// .contact-profileBody {
//   .app-bar {
//     box-shadow: unset;
// .MuiTabs-flexContainer {
//   border-bottom: 2px solid;
// }
//     button {
//       padding: 0px 0px;
//       min-height: 35px;
//       // border-bottom: 2px solid grey;
//     }
//   }

//   .timeline-body {
//     position: relative;
//     .timeline-dropdown {
//       position: absolute;
//       top: 0px;
//       min-width: 100px;
//       right: 0px;
//     }
//   }

//   .tab-content {
//     padding: 0px 10px 0px 15px;
//     height: 85vh;
//     overflow: auto;
//   }

// }

.contact-profileHeader {
  text-align: end;
}



.contact-personalInfo {
  text-align: center;
  margin-top: 20px;
}

.contact-personalInfo-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  // margin-bottom: 7px;
}

.contact-personalInfo-job {
  text-transform: uppercase;
  color: #7c83aa;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.contact-socialButtons {
  text-align: center;
}

.contact-socialButtons button {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 5px;
  margin-right: 5px;
}

.contact-socialButtons button:last-child {
  margin-right: 0px;
}

/* Profile Content */
.contact-content {
  padding: 20px;
  background: #fff;
  min-height: 460px;
}

.contact-profileBasicInfo {
  margin-bottom: 10px;

  .row {
    margin-bottom: 3px;
    margin-top: 3px;
  }

  p{
    margin-bottom: 0px;
  }
}

.contact-connections {
  margin-bottom: 10px;

  .MuiList-root {
    padding-left: 0px;
  }
}

.contact-editButton {
  text-align: end;
  margin-bottom: -33px;
}

.note-timeline {

  .MuiTimelineItem-missingOppositeContent:before {
    display: none;
  }

}



.connections {
  .MuiAvatar-root {
    width: 20px;
    height: 20px;
    font-size: inherit;
  }

  .relation {
    // margin: auto;
  }
}



.table-checkbox {
  padding: 0px !important;
}

.contactModalForm {
  .tabs {}
}


