.contact-form {

    img{
        width: 100px;
    }

    .contactForm-right {
        height: 80vh;
        overflow: auto;
    }
}