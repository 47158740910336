
/*  MobileSearch */
.cover{
    /* background-color: aquamarine; */
    width: 100%;
    padding: 10px;
    
    }

    .close-btn{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* padding: 5px; */
        font-size: 30px;
        margin-left: 0px;
    }

    .search-text{
        color:#84152D;
        font-size: 20px;
        font-weight: 600;
        margin-top: 35px;
        margin-bottom: 0;
        text-align: left !important;
        width: 100% ;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }


    .btn-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;
    }

    .btn-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    }
    .btn-div:last-child{

    /* margin-top: 0px; */
    }

    .btnM{
        background-color: #fff;
        border: 1px;
        border-color: grey !important;
        color: black  ;
        border-style: solid !important;
        width: 50%;
        font-weight: 600 !important;
        font-size: 18px !important;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding:8px 11px

    }
    .btnM2{
        background-color: #C1DAE6 !important;
        border: 1px;
        border-color: grey ;
        color: black;
        border-style: solid;
        width: 50%;
        font-weight: 600 !important;
        font-size: 18px !important; 
       
}

    .btnM2:disabled{
        background-color: #fff !important;
        color: grey !important;
        border-color: grey !important;
    }

    .search-from{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin-top: 10px; */
background-color: #E9f2f7;
padding: 15px;
    }

    .street-no{
    width: 40%;
    display: block;
    padding: 7px 7px 7px 7px;
    border: 1px solid #a7a6a6  !important;
    /* font-size: 18px  */
    /* color: #616161; */
    }


    .street-no::-webkit-input-placeholder { /* WebKit browsers */
        font-size: 14;
      }

      .street-no:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        font-size: 14;
      }

      .street-no::-moz-placeholder { /* Mozilla Firefox 19+ */
        font-size: 14;
      }

      .street-no:-ms-input-placeholder { /* Internet Explorer 10+ */
        font-size: 14;
      }

    .inputM{
        width: 100%;
        padding: 0px 7px 7px 7px;
        border: 1px solid #a7a6a6
    }
    .inputM2{
        width: 100%;
        padding: 3px 7px 7px 7px;
        border: 1px solid #a7a6a6;
        border-bottom: none !important;
        font-size: 18px !important;
        background-color: #fff;
    }
    .inputM3{
        width: 100%;
        font-size: 18px !important;
        background-color: #fff;
        display: block;
        padding: 7px 7px 7px 7px;
        border: 1px solid #a7a6a6  !important;
        color: #616161;
    }


    .search-btnM{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

    }
    .search2{
        width: 100%;
        background-color: #2bb677;
        /*border: 1px;
        *//*border-color: grey;
        */color: #fff;
        border-style: solid;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        padding:9px 11px;
        border: none;
        border-radius:5px ;
        -webkit-border-radius:5px ;
        -moz-border-radius:5px ;
        -ms-border-radius:5px ;
        -o-border-radius:5px ;
}
    
    .search2:disabled{
        width: 100%;
        background-color: #abe1c9;
      
        /* border-color: grey; */
        /* color: #fff;
        border-style: solid;
       font-size: 16px;
        font-weight: 600;
        text-transform: capitalize; */

    }

    .search2:hover{
        background-color: #2bb677;
        color: #fff;
    }



    /*  ProspectMobileView */

    .cover2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width:100%
    }

    .btn-container2{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    .header-layout{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px
    }

    .icon{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .plus{
    top: 0px !important;
    font-size: 30px;
    /* opacity: 0.6; */
  
    color: #fff;
    font-weight: bolder;
    }

    .newPlusBtn{
        height: 100% !important;
        width: 2.5rem;
        color: #fff !important;
        border-radius:0.2rem;
        border: 2px solid #2bb673;
        background-color:#2bb673 !important;
        opacity: 1 !important;
        -webkit-border-radius:0.2rem;
        -moz-border-radius:0.2rem;
        -ms-border-radius:0.2rem;
        -o-border-radius:0.2rem;
}

    .newPlusBtn:disabled{
        background-color:#cedfd7 !important;
        border: 1.5px solid #e2e5ec;
    }


    .Mphonecontainer{
        text-align: start;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .mphone{
        display: flex;
        justify-content: flex-start;
    }


    .mphone2{
        display: flex;
        flex-direction: column;
        margin-left: 5px;
    }

    .features{
        width: 100%
    }



    .mobile .modal-dialog {
        position: relative;
        width: auto;
        margin: 0rem;
        pointer-events: none;

    }

     .mobile .modal-dialog.modal-xl {
        height: 100%;
    }

    .mobile .modal-dialog.modal-xl .modal-content {
        border-radius: 0px;
        height: 100% !important;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }

    .MuiButton-root:hover.Mui-disabled {
        background-color: #2bb67733;
        color:#fff
    }
    .MuiButton-root.Mui-disabled {
        background-color: #2bb67733;
        color:#fff
    }

    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
      }


      .streetname{
        margin-top: 10px;
        margin-bottom: 0;
        color:#822433;
        font-weight: 500;
        font-size: 18px;
      }

      .suburbname{
        margin-top: 0px;
        margin-bottom: 0;
        color:#822433;
        font-weight: 500;
        font-size: 16px;
      }

.cover > input[type='text'],
input[type='number']{
    font-size: 18px !important;
  }

      /* input[type='text'],
input[type='number'],
textarea {
  font-size: 18px !important;
} */


.smsnphone{
    display: flex;
    justify-content: center;
    align-items: center;
}

.addbtnnew{
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    border: 1px solid #2bb673;;
    padding: 3px 5px;
    color: #fff;
    background-color: #2bb673;
    font-weight: bold;
}

.hidebtn{
    font-size: 16px;
}


    .addedStyle{
        position: fixed !important;
        top: 0;
        right: 0;
        left: 0;
        z-index: 98 !important;
    }

.appointment{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px;
}



#increaseFont > input[type='text'],
input[type='number']{
    font-size: 16px !important;
  }

  #increaseFont> input[type='text']{
    font-size: 16px !important;
  }



  .fontmodifier{
    font-size:13px !important;
  } 

 