.sortable-form-group {
    border: 1px solid lightblue;
    margin-bottom: 10px;
    padding: 0.5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* width: '100%' */
  }
  
  .sortable-form-group .form-group {
    align-self: stretch;
    flex: 1;
    align-items: center;
  }
  
  /* Drag handle */
  .sortable-form-group .handle {
    position: relative;
    top: 1px;
    display: block;
    width: 18px;
    height: 11px;
    opacity: 0.25;
    margin-right: 20px;
    cursor: row-resize;
    background: linear-gradient(
      180deg,
      #000,
      #000 20%,
      #fff 0,
      #fff 40%,
      #000 0,
      #000 60%,
      #fff 0,
      #fff 80%,
      #000 0,
      #000
    );
  }
  