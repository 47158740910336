 .a3_potrait { width: 100%; }
 /* .a3_potrait { width: 297mm; } */
.a4_landscape{width: 100%; min-width:1200px}
 /* .landscape{
    height: 841.89 px
 } */
@media  print {
  @page {
    size: auto !important;
    margin: 0;

    
  }
  @page {
    size: auto;
  }
  /* html,body{
 width: 1189px !important;height: 1680px !important;padding: 0 !important;
 font-size: 120% !important;
  } */
  #A4_potrait_print{
    /* width: 90%; */
    width: 1189px !important;height: 1680px !important;padding: 0 !important;
    font-size: 12pt !important;
    /* border: none !important; */
  }

  #A4_landscape_print{
    height: 1188px !important;width: 1680px !important;padding: 0 !important;
    font-size: 12pt !important;
    border: none !important;
  
  }
  .a4_landscape{width: 100%; min-width: 100%;}
  #A3_potrait_print_img{
    height: 750px !important;
    /* object-fit:scale-down !important; */
    object-fit: fill !important;
  }
  #A4_potrait_print_img{
    height: 740px !important;
    /* object-fit:scale-down !important; */
    object-fit: fill !important;
  }
  #A4_landscape_print_img{
    height: 640px !important;
    /* object-fit:scale-down !important; */
    object-fit: fill !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  #A4_landscape_print_img4{
    height: 783px !important;
    /* object-fit:scale-down !important; */
    object-fit: fill !important;
  }
  .fourphotos_landscape{
    height: 400px !important;
  }







  /* A4 design Update  */
#A4_header{
  font-size: 26pt !important;
  /* text-transform: uppercase; */
}
  #A4_address{
    font-size: 26pt !important;
  }
  #A4_price{
    font-size: 26pt !important;
  }
  .A4_spacer{
    margin-bottom: 6% !important;
  }
  #A_office{
    margin-top: 6% !important;
  }


    /* A4 design Update  */









  .header_img{
    height: 710px !important;
    /* object-fit:scale-down !important; */
    object-fit: fill !important;
  }
  .a4_potrait{
    padding: 0px !important;
  }
  .a4_landscape{
    padding: 0px !important;
  }
  .landscapeOverlay{
    padding: 0px !important;
    position: relative !important;
  }
  
  .auctionLandscape{
    padding: 0px !important;
    position: relative !important;
  }
  .forsale{
    padding: 0px !important;
    position: relative !important;
  }
  .auction{
    padding: 0px !important;
    position: relative !important;
  }
  .forlease{
    padding: 0px !important;
    position: relative !important;
  }
  .a4_potrait{
    width: 100% !important;
    /* width: 1189px !important;height: 1680px !important;padding: 0 !important; */
  }

  #agent_openhome{
    height: 350px !important;
    margin-top: 10px !important
  }
 .description {
    font-size: 16pt !important;
    height: 35vh !important;
    /* overflow:visible !important; */
    padding-bottom: 15px !important;
    writing-mode: horizontal-tb;
  }

  #landscapedesp{
    height: 250px !important
  }
  .address{
    font-size: 22pt !important;
  } 
  
  .header{
    font-size: 22pt !important;
  }
  #upperCase{
    text-transform: uppercase !important;
  }
  /* .suburb {
    font-size: 18pt !important ;
  } */
  #A4_price_guide {
    font-size: 26pt !important;
    margin-bottom: 10px !important;
    font-weight: bold  !important;
  }
   #A4_print_textValue  {
    font-size: 18pt !important;
    font-family:Cormorant Garamond !important;
  }
   
   #A4_print_agent {
    margin-top: 10px !important;
    font-size: 18pt !important;
  }
   .price {
    font-size: 22pt !important;
  }
   .openHomeDate {
    font-size: 15pt !important;
font-weight: bold  !important;
font-family:Open Sans,sans-serif !important;
  } 
   .auctionDate {
    font-size: 15pt !important;
font-weight: bold  !important;
font-family:Open Sans,sans-serif !important;
  } 
  .agent_name{
    font-size: 18pt !important;
  }
   .agent_mail{
    font-size: 18pt !important;
  }
    .agent_phone{
      font-size: 18pt !important;
    }
     .officeName {
      font-size: 18pt !important;
    }
     .office_phone{
    font-size: 15pt !important;
  }
     .office_website{
    font-size: 15pt !important;
  }

  .disclaimer{
    font-size: 14pt !important;
  }

  .officeFooter{
    margin-top: 30px !important;
  }
.logo{
  margin-top: 25px !important;
}

#sub_image_a4_portrait{
  height:280px !important;
  border: none !important;
}

.fourphotos {
  bottom:320px !important;
  z-index: 1000 !important;
}
.fourphotosOpen {
  bottom:320px !important;
  /* right: 4rem ; */
  z-index: 1000 !important;
  /* right: 10rem !important; */
}
.fourphotosAuction{
  bottom:320px !important;
  /* right: 10rem !important; */
}

.fourphotosA4 {
  bottom:320px !important;
  z-index: 1000 !important;
}
.fourphotosOpenA4 {
  bottom:320px !important;
  /* right: 6rem !important; */
  z-index: 1000 !important;
  /* right: 10rem !important; */
}

.fourphotosAuctionA4{
  bottom:300px !important;
  /* right: 10rem !important; */
}

#descriptionWidth{
  width:450px !important;
  height: 100% !important;
}

#new_width{
  width:70%  !important;
}
#new_width2{
  width:30%  !important;
}

#openHomeDate_landscape {
  font-size: 13.5pt !important;
font-weight: bold !important;
} 
#auctionDate_landscape {
  font-size: 13.5pt !important;
font-weight: bold !important;
} 


#office_phone_landscape{
  font-size:  1.5vw  !important;
}
   #office_website_landscape{
  font-size:  1.5vw !important;
}


/* A3 design */
#A3_landscape_print{
  height: 1179px !important;width: 1670px !important;padding: 0 !important;
  font-size: 12pt !important;

}
#A3_landscape_print2{
  height: 1175px !important;width: 1670px !important;padding: 0 !important;
  font-size: 12pt !important;

}

#office_phone_landscape2{
  font-size:  1vw  !important;
}
   #office_website_landscape2{
  font-size:  1vw !important;
}
#A3description {
height: 480px !important;
  /* overflow:visible !important; */
}
#A3descriptionAuction {
  height: 380px !important;
  /* overflow:hidden !important; */
}

#A3_portrait_print{
  /* width: 90%; */
  width: 1250px !important;height: 1765px !important;padding: 0 !important;
  font-size: 12pt !important;
}

#A3_potrait_print_img{
  height: 750px !important;
  /* object-fit:scale-down !important; */
  object-fit: fill !important;
}
#A3_potrait_print_img2{
  height: 750px !important;
  /* object-fit:scale-down !important; */
  object-fit: fill !important;
}

#newA3header_img{
  height: 695px !important;
  /* object-fit:scale-down !important; */
  object-fit: fill !important;
}
#A3header_img{
  height: 650px !important;
  /* object-fit:scale-down !important; */
  object-fit: fill !important;
}
 /*  to change height of paper    to change height of paper   to change height of paper   to change height of paper  */
#A3_landscape_print_img4{
  height: 778px !important;
  /* object-fit:scale-down !important; */
  object-fit: fill !important;
}
#A3_landscape_print_img{
  height: 680px !important;
  /* object-fit:scale-down !important; */
  object-fit: fill !important;
}
#noBorder{
  border: none !important;
}
}

 .a4_potrait{
   width: 100%;
   /* width: 1189px !important;height: 1680px !important;padding: 0 !important; */
 }

 .a4_potrait .forsale{
   padding: 20px;
 }
 .a4_potrait .auction{
   padding: 20px;
 }
 .a4_potrait .forlease{
   padding: 20px;
 }

 .suburb {
  font-size: 35pt !important ;
  font-weight: 900;
  font-family: Cormorant Garamond;
}

.header{
  font-size: 28pt;
  text-transform: capitalize ;
  overflow: hidden;
  font-family:Cormorant Garamond !important;
}
#caps{
  font-size: 22pt;
  text-transform: none !important;
}
#caps2{
  font-size: 22pt;
  text-transform: uppercase !important;
}
#A4_print_agent {
  font-size: 18pt !important;
  padding-left:20px
}

 .description {
  writing-mode: horizontal-tb !important;
  -webkit-writing-mode: horizontal-tb !important;
  -ms-writing-mode: horizontal-tb !important;
}

.landscapeOverlay .header {
  text-transform: none !important;
}
.auctionLandscape .header {
  text-transform: none !important;
}

.openHomeDate{
  font-family:Open Sans,sans-serif !important;
}

.auctionDate{
  font-family:Open Sans,sans-serif !important;
}

#descriptionWidth{
  flex-direction: column;
}

.addedStyle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.disclaimerBlock{
  position: relative !important;
}


#new_width2{
  position: relative !important;
}

#descriptionWidth{
  width:450px !important;
  height: 80% !important;
}

#upperCase{
  text-transform: uppercase !important;
}

.A4_spacer{
  margin-bottom: 5% ;
}
/* #landscapedesp{
  height: 250px !important
} */

/* #A4_landscape_print{
  height: 118.78121878121878vh ;
  font-size: 12pt !important;

} */

 /* #A4_landscape_print{
  @media print{
    @page {
      size: landscape;
      margin: 0;
      height: 1189px !important;width: 1680px !important;padding: 0 !important;
      font-size: 12pt !important;
    }
  }

 
  
} */