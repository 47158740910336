.makeAnOffer {
    .DraftEditor-root {
        min-height: 300px;
        position: relative;
    }
}
.makeAnOfferItem > :last-child {
    hr {
        display: none;
    }
  }