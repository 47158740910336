// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
.orange-color {
    color: #e67e22;
}

.ant-spin-dot-item {
    background-color: #822633 !important;
}