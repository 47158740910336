//
// Custom Example 2 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
  padding: 1rem 0.5rem;
  color: kt-brand-color();

  .my-custom-element {
    margin-left: 1rem;
  }
}

@include kt-tablet-and-mobile {
  .my-custom-style {
    padding: 0.5rem 0.25rem;

    .my-custom-element {
      margin-left: 0.5rem;
    }
  }
}

.horizontal-scroll-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .cards {
    display: inline-block;
    height: 12rem;
    padding: 3rem;
    display: inline-block;
  }
}

.rdw-fontfamily-wrapper {
  width: "2rem";
}

.small-fr-butt_on {
  margin: 5px;
  height: 25px;
  padding-top: 3px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 2px !important;

  background-color: lightblue !important;
  color: black !important;
  border: 1px solid !important;
  border-color: lightblue !important;
}

.bulk-filter-buttons {
  width: 120px ;
  height: 30px;
}
.all-button{
  background-color: #3a3a3b !important;
}
.prospect-button{
  background-color: #fcf8bd !important;
}
.buyer-button{
  background-color: #bfe1f7 !important;
}
.landlord-button{
  background-color: #f7b1b9 !important;
}
.seller-button{
  background-color: #ffdeba !important;
}
.tenant-button{
  background-color: #d789ba !important;
}
.services-button{
  background-color: #d1d2d4 !important;
}
.others-button{
  background-color: #e7e7e8 !important;
  color: #000!important;
}
.prospect-border-bottom-filter{
  border-bottom: solid 4px #fcf8bd !important;
}
.buyer-border-bottom-filter{
  border-bottom: solid 4px #bfe1f7  !important;
}
.landlord-border-bottom-filter{
  border-bottom: solid 4px #f7b1b9  !important;
}
.seller-border-bottom-filter{
  border-bottom: solid 4px #ffdeba  !important;
}
.tenant-border-bottom-filter{
  border-bottom: solid 4px #d789ba  !important;
}
.buyer-seller-border-bottom-filter{
  border-bottom: solid 4px   !important;
  border-image: linear-gradient(to right,#bfe1f7 50% ,#ffdeba  50%);
  border-image-slice: 1;
}
.landlord-tenant-border-bottom-filter{
  border-bottom: solid 4px   !important;
  border-image: linear-gradient(to right,#f7b1b9  50%,#d789ba 50%);
  border-image-slice: 1;
}

.video-preview-buttons {
  z-index: 999;
  position: relative;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.custom-filter-drawer {
  height: auto !important;
  min-height: 100%;
}

ul {
  cursor: pointer;
}

.shorter-select {
  width: 6vh;
}

.modal-xl {
  max-width: 1000px;
}

.clickable {
  cursor: pointer;
  padding-left: 0 !important;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blue-border {
  border: 2px solid kt-state-color(wb-dark-blue);
}

.modal-xxl {
  max-width: 1400px !important;
}

.required-border {
  border-bottom: 2px solid #f73378;
}

.not-required-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.MuiAutocomplete-option {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.rbc-timeslot-group {
  min-height: 80px;
}

.material-select-equal-dropdown {
  width: 14vh;
}

.MuiAutocomplete-popper {
  width: 300px !important;
}

.min-content-width {
  width: fit-content;
}

.MuiFormControl-root {
  border: 0 !important;
  margin: 0;
  display: inline-flex !important;
  padding: 0 !important;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}
// @media only screen and (device-width: 768px) {
//   .theme-header-custom {
//     overflow-x: visible !important;
//   }
// }
.theme-header-custom {
  overflow-x: visible !important;
}

.MuiToggleButton-root {
  color: #00000061 !important;
  border: 1px solid #0000001f !important;
  padding: 11px !important;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-family: arial;
  font-weight: 500 !important;
  line-height: 1.75;
  border-radius: 4px !important;
  text-transform: uppercase;
}

.tooltipContent {
  font-size: 1.2rem;
  padding: 5px;
}
.tooltipIcon {
  font-size: 1.4rem;
}

.editorMarketing {
  .DraftEditor-root {
    height: 20vh;
    border: 1px solid #f1f1f1;
    padding: 2rem;
    overflow: auto;
  }
}
.marketingSubTitle {
  color: lightslategray;
}

.draggingAvatar {
  top: auto !important;
  left: auto !important;
}

.numberCircle {
  border-radius: 50%;
  width: 23px;
  height: 22px;
  background: #000;
  border: 2px solid #666;
  color: #fff;
  text-align: center;
  font: 17px Arial, sans-serif;
  margin-right: 25px;
}

.template-image {
  width: 100%;
}

.overlayImage {
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
}
.backgroundImage {
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.grabbingCursor {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.grabCursor {
  cursor: -webkit-grab;
  cursor: grab;
}

.overText {
  font-size: xx-large;
  color: #fff;
  font-weight: bold;
}

.template-image-container {
  position: relative;
  display: flex;
  justify-content: center;

  .template-mask {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    // background-image: url("https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/overlays/Profile-Image-Overlay.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: xx-small;
    color: white;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(100, 100, 105);

    .listing-template-image {
      opacity: 0.3;
    }

    .template-mask {
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
	.seller-report-form {
    .seller-report-container {
      width: 450px !important;
    }
  }
}

@media only screen and (min-width: 850px) and (max-width: 1000px) {
	.seller-report-form {
    .seller-report-container {
      width: 325px !important;
      ._seller_report_logo {
        min-height: 225px !important;
      }
      .counter-box {
        padding: 8px 20px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
	.seller-report-form {
    .seller-report-container {
      width: 200px !important;
      ._seller_report_logo {
        min-height: 225px !important;
      }
      .counter-box {
        padding: 0px 13px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .seller-report-form {
    .seller-report-container {
      width: 350px !important;
    }
  }
}

@media (max-width: 767px) {
  .seller-report-form {
    .seller-report-container {
      ._seller_report_logo {
        min-height: 250px !important;
      }
    }
  }
}